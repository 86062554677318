<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Equipos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Operacion</li>
                  <li class="breadcrumb-item active">Equipos</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="col-3 pt-2 pb-2">
                <h5 class="mb-0">Formulario crear equipos</h5>
              </div>
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="tab-Principal"
                    data-toggle="tab"
                    href="#Principal"
                    >Principal</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="tab-Documentos"
                    data-toggle="tab"
                    href="#Documentos"
                    v-if="form.id != null"
                    >Documentos</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade active show" id="Principal">
                  <div class="card-body">
                    <div class="row">
                      <div :class="form.id ? 'col-md-9' : 'col-md-12'">
                        <div class="row">
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="empresa_id">Empresa</label>
                              <select
                                class="form-control form-control-sm"
                                id="empresa_id"
                                v-model="form.empresa_id"
                                :disabled="opcion == 1"
                                :class="
                                  $v.form.empresa_id.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              >
                                <option value="">Seleccione...</option>
                                <option
                                  v-for="empresa in listasForms.empresas"
                                  :key="empresa.id"
                                  :value="empresa.id"
                                >
                                  {{ empresa.razon_social }}
                                </option>
                              </select>
                              <div
                                class="error"
                                v-if="!$v.form.empresa_id.required"
                              >
                                Seleccione una empresa
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="placa">Placa</label>
                              <input
                                :disabled="opcion == 1"
                                type="text"
                                class="form-control form-control-sm"
                                id="placa"
                                maxlength="40"
                                v-model="form.placa"
                                @blur="validaVehiculo()"
                              />
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="serial">Serial o linea</label>
                              <input
                                :disabled="opcion == 1"
                                type="text"
                                class="form-control form-control-sm"
                                id="serial"
                                maxlength="30"
                                v-model="form.serial"
                                @blur="validaVehiculo()"
                              />
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="">Peso</label>
                              <input
                                :disabled="opcion == 1"
                                type="text"
                                class="form-control form-control-sm"
                                v-model="form.peso"
                                :class="
                                  $v.form.peso.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="marca">Marca</label>
                              <select
                                :disabled="opcion == 1"
                                class="form-control form-control-sm"
                                id="marca"
                                v-model="form.marca"
                                :class="
                                  $v.form.marca.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              >
                                <option value="">Seleccione...</option>
                                <option
                                  v-for="marca in listasForms.marcas"
                                  :key="marca.numeracion"
                                  :value="marca.numeracion"
                                >
                                  {{ marca.descripcion }}
                                </option>
                              </select>
                              <div class="error" v-if="!$v.form.marca.required">
                                Seleccione una marca
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label>Tipo de Equipo</label>
                              <select
                                :disabled="opcion == 1"
                                class="form-control form-control-sm"
                                v-model="form.tipo_equipo"
                                :class="
                                  $v.form.tipo_equipo.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              >
                                <option value="">Seleccione...</option>
                                <option
                                  v-for="tipo_equipo in listasForms.tipos_equipo"
                                  :key="tipo_equipo.numeracion"
                                  :value="tipo_equipo.numeracion"
                                >
                                  {{ tipo_equipo.descripcion }}
                                </option>
                              </select>
                              <div
                                class="error"
                                v-if="!$v.form.tipo_equipo.required"
                              >
                                Seleccione un tipo de vehículo
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="">Propiedad</label>
                              <select
                                :disabled="opcion == 1"
                                class="form-control form-control-sm"
                                id="propiedad"
                                v-model="form.propiedad"
                                :class="
                                  $v.form.propiedad.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              >
                                <option value="">Seleccione...</option>
                                <option
                                  v-for="propiedad in listasForms.propiedades"
                                  :key="propiedad.numeracion"
                                  :value="propiedad.numeracion"
                                >
                                  {{ propiedad.descripcion }}
                                </option>
                              </select>
                              <div
                                class="error"
                                v-if="!$v.form.propiedad.required"
                              >
                                Seleccione una propiedad
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="">Combustible</label>
                              <select
                                :disabled="opcion == 1"
                                class="form-control form-control-sm"
                                id="combustible"
                                v-model="form.combustible"
                                :class="
                                  $v.form.combustible.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              >
                                <option value="">Seleccione...</option>
                                <option
                                  v-for="combustible in listasForms.combustibles"
                                  :key="combustible.numeracion"
                                  :value="combustible.numeracion"
                                >
                                  {{ combustible.descripcion }}
                                </option>
                              </select>
                              <div
                                class="error"
                                v-if="!$v.form.combustible.required"
                              >
                                Seleccione una combustible
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="modelo">Modelo</label>
                              <input
                                :disabled="opcion == 1"
                                type="number"
                                class="form-control form-control-sm"
                                id="modelo"
                                min="1970"
                                max="2100"
                                v-model="form.modelo"
                                :class="
                                  $v.form.modelo.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                              <div
                                class="error"
                                v-if="!$v.form.modelo.required"
                              >
                                Diligencie un modelo
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3" v-if="form.id">
                        <label for="imagen">Foto del Equpo</label>
                        <div
                          class="form-group"
                          v-if="form.link_fotografia == null"
                        >
                          <input
                            type="file"
                            class="form-control-file"
                            accept="image/x-png,image/gif,image/jpeg"
                            @change="obtenerImagen"
                          />
                        </div>
                        <figure class="form-group" v-if="miniLogo">
                          <div class="button-container">
                            <img
                              :src="miniLogo"
                              alt="Logo"
                              width="236"
                              height="125"
                              class="rounded mx-auto d-block"
                            />
                            <a
                              @click="destroyImage()"
                              id="BotonEliminar"
                              v-if="form.link_fotografia"
                              href="#"
                              :disabled="opcion == 1"
                              >Eliminar <i class="fas fa-window-close"></i
                            ></a>
                          </div>
                        </figure>
                        <div class="row">
                          <div class="col-md-9"></div>
                          <div class="col-md-3" v-if="archivoValido">
                            <button
                              type="button"
                              @click="saveImage()"
                              class="btn btn-success"
                              data-toggle="tooltip"
                              data-html="true"
                              title="Guardar Imagen"
                            >
                              <i class="fa fa-upload"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-8 offset-md-2">
                        <EquipoLinea
                          v-if="
                            form.id != null &&
                              $store.getters.can(
                                'admin.equipos.assingOrRevokeLinea'
                              )
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="Documentos" v-if="form.id">
                  <EquipoDocumento
                    v-if="form.id != null"
                    ref="EquipoDocumento"
                  />
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid"
                    @click="save()"
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import EquipoDocumento from "./EquipoDocumento";
import EquipoLinea from "./EquipoLinea";
import Loading from "../../../../components/Loading";

export default {
  name: "EquipoForm",
  components: {
    EquipoDocumento,
    EquipoLinea,
    Loading,
  },
  data() {
    return {
      opcion: 1,
      cargando: false,
      form: {},
      uploadPercentage: 0,
      uploadPercentageFoto: 0,
      miniLogo: null,
      archivoValido: false,
      fileFotografia: null,
      accion: "",
      metodo: "",
      listasForms: {
        empresas: [],
        /*    tipos_capacidad: [], */
        marcas: [],
        tipos_equipo: [],
        tipos_carroceria: [],
        colores: [],
        estados: [],
        documentos: [],
        propiedades: [],
        combustibles: [],
      },
      modal: {
        title: "",
        accion: "",
      },
      odometroInicial: {},
      id: this.$route.params.id,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations: {
    form: {
      empresa_id: {
        required,
      },
      placa: {
        required,
      },
      serial: {
        required,
      },
      peso: {
        required,
      },
      marca: {
        required,
      },
      tipo_equipo: {
        required,
      },

      modelo: {
        required,
        maxLength: maxLength(4),
        minLength: minLength(4),
      },

      propiedad: {
        required,
      },
      combustible: {
        required,
      },
    },
  },
  methods: {
    async getEmpresas() {
      await axios.get("/api/admin/empresas/lista").then((response) => {
        this.listasForms.empresas = response.data;
      });
    },

    /*     async getTiposCapacidad() {
      await axios.get("/api/lista/13").then((response) => {
        this.listasForms.tipos_capacidad = response.data;
      });
    }, */

    async getMarcas() {
      await axios.get("/api/lista/12").then((response) => {
        this.listasForms.marcas = response.data;
      });
    },

    async getTiposEquipos() {
      await axios.get("/api/lista/129").then((response) => {
        this.listasForms.tipos_equipo = response.data;
      });
    },

    async getEstados() {
      await axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    async getPropiedades() {
      await axios.get("/api/lista/23").then((response) => {
        this.listasForms.propiedades = response.data;
      });
    },

    async getCombustibles() {
      await axios.get("/api/lista/24").then((response) => {
        this.listasForms.combustibles = response.data;
      });
    },

    validaVehiculo() {
      let params = {
        placa: this.form.placa,
        serial: this.form.serial,
      };
      axios
        .get("/api/admin/equipos/lista", {
          params,
        })
        .then((response) => {
          if (response.data.length > 0) {
            this.form.placa = null;
            this.form.serial = null;
            this.$swal({
              icon: "error",
              title: "El equipo digitado ya se encuentra creado...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        });
    },

    // Funciones para carga de imagenes
    obtenerImagen(e) {
      this.miniLogo = null;
      this.archivoValido = false;
      if (e.target.files[0]) {
        this.cargando = true;
        const file = e.target.files[0];
        if (this.validaArchivo(file)) {
          this.archivoValido = true;
          this.fileFotografia = file;
          this.cargarImagen(file);
        }
      }
    },

    validaArchivo(file) {
      let flag = true;
      if (file.size > 2048000) {
        flag = false;
        this.fileFotografia = null;
        this.cargando = false;
        this.$swal({
          icon: "error",
          title: `El Archivo es muy pesado para subirlo`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return flag;
    },

    cargarImagen(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.miniLogo = e.target.result;
      };
      reader.readAsDataURL(file);
      this.cargando = false;
    },

    saveImage() {
      this.cargando = true;
      let me =  this;
      let formData = new FormData();
      formData.append("id", me.form.id);
      formData.append("link_fotografia", me.fileFotografia);
      axios
        .post("api/admin/equipos/foto", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          me.$swal({
            icon: "success",
            title: "Los datos se guardaron exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          me.archivoValido = false;
          me.form.link_fotografia = response.data.link_fotografia;
          me.cargando = false;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    destroyImage() {
      this.$swal({
        title: "Esta seguro de eliminar esta Imagen?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/admin/equipos/foto/" + this.form.id)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "La imagen se elimino correctamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch(function(error) {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error" + error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
          this.miniLogo = null;
          this.form.link_fotografia = null;
        }
      });
    },

    save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        axios({
          method: this.metodo,
          url: "/api/admin/equipos",
          data: this.form,
        })
          .then((response) => {
            this.form = response.data;
            this.$swal({
              icon: "success",
              title: "El Vehículo se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
      }
    },

    back() {
      return this.$router.replace("/Admin/Equipos");
    },

    init() {
      this.accion = this.$route.params.accion;
      this.opcion = this.$route.params.opcion;
      if (this.$route.params.accion == "Editar") {
        this.form = this.$route.params.data_edit;
        this.miniLogo = this.uri_docs + this.form.link_fotografia;
        this.metodo = "PUT";
      } else {
        this.metodo = "POST";
      }
    },
  },
  computed: {
    logo() {
      return this.miniLogo;
    },
  },
  async mounted() {
    this.cargando = true;
    await this.getEmpresas();
    /*     await this.getTiposCapacidad(); */
    await this.getMarcas();
    await this.getTiposEquipos();
    await this.getEstados();
    await this.getCombustibles();
    await this.getPropiedades();
    await this.init();
    this.cargando = false;
  },
  created() {},
};
</script>
<style>
.button-container {
  display: inline-block;
  position: relative;
}

.button-container a {
  bottom: 3em;
  right: 4em;
  color: white;
  text-transform: uppercase;
  padding: 1em 1.8em;
  opacity: 0.3;
  color: #fff !important;
  background: #ff0000;
  position: absolute;
  outline: 2px solid;
  outline-color: rgba(248, 8, 8, 0.89);
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.button-container a:hover {
  box-shadow: inset 0 0 20px rgba(248, 8, 8, 0.89),
    0 0 20px rgba(248, 8, 8, 0.89);
  outline-color: rgba(49, 138, 172, 0);
  outline-offset: 80px;
  text-shadow: 3px 3px 3px #fff;
}
</style>
